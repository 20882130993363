import wowInit from "./module/wowInit";
import adjustViewport from "./module/adjustViewport";
import smoothScroll from "./module/smoothScroll";
import loadScroll from "./module/loadScroll";
import headerFixed from "./module/headerFixed";
import toggleGnav from "./module/toggleGnav";
import megamenu from "./module/megamenu";
import showGotop from "./module/showGotop";
import parallax from "./module/parallax";
import tab from "./module/tab";
import popup from "./module/popup";
import yearsSlider from "./module/yearsSlider";
import accordion from "./module/accordion";
import topMvSlider from "./module/topMvSlider";
import topServiceSlider from "./module/topServiceSlider";
import topIntroductionAnimation from "./module/topIntroductionAnimation";
import topPickupSlider from "./module/topPickupSlider";
import topPartnerSlider from "./module/topPartnerSlider";
import businessPickupSlider from "./module/businessPickupSlider";
import branchFilter from "./module/branchFilter";
import fixCategories from "./module/branchFixCategories";
import "../scss/style.scss";

wowInit();
adjustViewport();
smoothScroll();
loadScroll();
headerFixed();
toggleGnav();
megamenu();
showGotop();
parallax();
tab();
popup();
yearsSlider();
accordion();

// トップページ
if (document.body.id === 'top') {
    topMvSlider();
    topServiceSlider();
    topIntroductionAnimation();
    topPickupSlider();
    topPartnerSlider();

// 事業案内
} else if (document.body.id === 'business') {
    businessPickupSlider();

// 支店案内
} else if (document.body.id === 'branch') {
    branchFilter();
    fixCategories();
}


