import scrollTo from './scrollTo';

// URLにハッシュがあればページ読み込み時にスクロール
const loadScroll = () => {
    const hash = location.hash; //URLハッシュを取得
    if (!hash || !hash.match(/^#.+$/)) return; //URLハッシュが存在しなければ終了

    window.onload = () => {
        const target = document.getElementById(hash.replace('#', '')); //対象要素を取得
        if (!target) return; //対象要素が存在しなければ終了

        const headerH = document.querySelector('.js_header').clientHeight; //ヘッダーの高さを取得

        // targetまでの位置を取得
        const getEndPos = () => {
            const rect = target.getBoundingClientRect().top; // ターゲットの位置を取得
            const offset = window.scrollY; // 現在のスクロール量を取得
            return rect + offset - headerH; //最終的な位置を割り出す
        }
        
        // targetまでの位置が3回連続で同じ値ならスクロール（位置が微妙にずれる場合があるため）
        let sameValueCount = 0;
        let lastEndPos = null;
        const checkEndPos = () => {
            const endPos = getEndPos();

            if (endPos === lastEndPos) {
                sameValueCount++;
                if (sameValueCount === 3) {
                    window.scrollTo(0, 0); //一旦画面トップに移動
                    scrollTo(endPos); //スクロール
                    return;
                }
            } else {
                sameValueCount = 1;
            }
            lastEndPos = endPos;
            requestAnimationFrame(checkEndPos);
        };

        requestAnimationFrame(checkEndPos);
    }
}

export default loadScroll;