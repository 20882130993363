import { resetAccordion } from './megamenu';

// グロナビを開く（SP）
const toggleGnav = () => {
    const trigger = document.querySelector('.js_menubar'); //メニューバー
    const target = document.querySelector('.js_gnav'); //グロナビ

    if (!trigger || !target) return;

    // グロナビ展開後の高さを返す
    const getTargetH = () => {
        const targetH = target.querySelector('.js_gnav_body').clientHeight; //グロナビの高さ
        const displayH = window.innerHeight - document.querySelector('.js_header').clientHeight; //表示エリアの高さ (画面の高さ-ヘッダーの高さ)

        // グロナビが画面内に収まる場合
        if (targetH < displayH) {
            return displayH; //表示エリアの高さを返す
        // グロナビにスクロールが発生する場合
        } else {
            return targetH; //グロナビの高さを返す
        }
    }
    
    const toggle = () => {
        // 開く時
        if (!trigger.classList.contains('is_active')) {
            target.style.height = getTargetH() + 'px'; //グロナビに高さを設定
        // 閉じる時
        } else {
            resetAccordion(); //内部のアコーディオンを閉じる
            target.style.height = '0';
        }

        document.body.classList.toggle('is_fixed'); //背景固定
        trigger.classList.toggle('is_active'); //トリガーにclassを付与
        target.classList.toggle('is_show'); //グロナビにclassを付与
    }

    trigger.addEventListener('click', toggle);
}

export default toggleGnav