import getMq from './getMq';

// メガメニュー制御
const triggerList = document.querySelectorAll('.js_megamenu_trigger'); //開閉用のlabelを取得
const targetList = document.querySelectorAll('.js_megamenu'); //megamenuを取得

const megamenu = () => {
    // アコーディオン（SP）
    const toggleAccordion = () => {
        const toggle = (e) => {
            const mq = getMq();
            if (mq !== 'sm' && mq !== 'md') return;

            const targetId = e.target.getAttribute('for'); //対象の固有IDを取得
            const target = Array.from(targetList).find(val => val.dataset.id === targetId); //IDに一致するmegamenuを取得
            const targetH = target.querySelector('.js_megamenu_body').clientHeight;
            e.target.classList.toggle('is_active'); //labelにclassを付与
            target.classList.toggle('is_show'); //megamenuにclassを付与

            // megamenuに高さを設定
            if (e.target.classList.contains('is_active')) {
                target.style.height = targetH + 'px';
            } else {
                target.style.height = 0;
            }
        }

        // トリガーにクリックイベントを設定
        for (let i = 0; i < triggerList.length; i++) {
            const trigger = triggerList[i];
            trigger.addEventListener('click', toggle);
        }
    }
    
    // メガメニューを閉じる (PC)
    const closeMegamenu = () => {
        const closeBtnList = document.querySelectorAll('.js_megamenu_close'); //閉じるボタンを取得

        const close = () => {
            const trigger = document.querySelector('.js_megamenu_trigger + input:checked'); //チェックが入っているラジオボタンを取得
            if (!trigger) return; //チェックが入っていなければ終了
            trigger.checked = false; //チェックが入っていたら外す
        }

        //閉じるボタンにクリックイベントを設定
        for (let i = 0; i < closeBtnList.length; i++) {
            const closeBtn = closeBtnList[i];
            closeBtn.addEventListener('click', close);
        }
    
        // documentにクリックイベントを設定（メガメニュー以外の領域をクリックしたら閉じる）
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.js_header')) { //クリックされた要素がヘッダーの子要素でないかどうかをチェック
                close(); //閉じる
            }
        });
    }

    toggleAccordion();
    closeMegamenu();
}

const resetAccordion = () => {
    triggerList.forEach(trigger => {
        trigger.classList.remove('is_active');
    });
    targetList.forEach(target => {
        target.classList.remove('is_show');
        target.style.height = 0;
    });
}

export default megamenu;
export { resetAccordion };

