import getDevice from './getDevice';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// トップページ　ピックアップ　スライダー
const topPickupSlider = () => {
    const el = document.querySelector('.js_topPickupSlider');
    if (!el) return;

    let swiperInstance; //swiperインスタンス

    // swiper初期化
    const swiperInit = () => {
        const params = {
            modules: [Pagination],
            speed: 500,
            slidesPerView: 'auto',
            spaceBetween: 39,
            pagination: {
                el: '.js_pickupSlider_pagination',
                clickable: true,
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            }
        }
        swiperInstance = new Swiper(el, params);
    }

    // 画面幅によって有効/無効を切り替え
    const switchSwiper = () => {
        if (!swiperInstance) return;
        
        const device = getDevice(true); //sp or tab or pc
        
        // SPもしくはタブレットの場合
        if (device === 'sp' || device === 'tab') {
            swiperInstance.enable();
            
        // PCの場合
        } else {
            swiperInstance.disable();
        }
    }

    swiperInit();
    switchSwiper(); //初期実行
    window.addEventListener('resize', switchSwiper); //リサイズした時に実行
}

export default topPickupSlider