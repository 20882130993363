// ポップアップ
const popup = () => {
    // ポップアップボタンを取得
    const triggerList = document.querySelectorAll('.js_popup_trigger');
    // ポップアップボタンがなければ終了
    if (!triggerList.length) return;

    // 全てのポップアップボタンに実行
    for (let i = 0; i < triggerList.length; i++) {
        const trigger = triggerList[i]; //ポップアップボタン
        const target = trigger.nextElementSibling; //ポップアップの中身を取得

        // トリガーの次の要素がポップアップクラスを持っていない場合処理しない
        if (!target.classList.contains('js_popup_body')) continue;

        // 中身要素をbodyの最後に移動
        const clonedTarget = teleportEl(target);

        // 閉じる要素を取得
        const closeEl = getCloseEl(clonedTarget);
    
        // それぞれにクリックイベントを設定
        trigger.addEventListener('click', () => show(clonedTarget));
        closeEl.close.addEventListener('click', () => hide(clonedTarget));
        closeEl.overlay.addEventListener('click', () => hide(clonedTarget));
    }
}

// 対象要素をbodyの最後に移動させる
const teleportEl = (target) => {
    const clonedTarget = target.cloneNode(true); //対象要素を複製
    document.body.appendChild(clonedTarget); //複製要素をbodyの最後に追加
    target.remove(); //元の要素を削除

    return clonedTarget; //複製要素を返す
}

// 閉じる要素を取得
const getCloseEl = (target) => {
    let close; //閉じるボタン
    let overlay; //オーバーレイ
    for (let i = 0; i < target.children.length; i++) {
        const child = target.children[i];
        if (child.classList.contains('js_popup_close')) {
            close = child;
        } else if (child.classList.contains('js_popup_overlay')) {
            overlay = child;
        }
    }
    return { close, overlay };
}

// 表示関数
const show = (target) => {
    target.classList.add('is_active');
    const displayEl = target.querySelector('.js_popup_display'); //表示領域
    const contentsEl = target.querySelector('.js_popup_contents'); //中身
    const windowH = window.innerHeight; //ウィンドウの高さ
    const contentsH = contentsEl.clientHeight; //中身の高さ

    // 中身の高さがウィンドウの高さより高い場合、スクロールバーを表示
    if (contentsH > windowH) {
        displayEl.classList.add('is_scroll');
    }
}

// 非表示関数
const hide = (target) => {
    target.classList.remove('is_active');
}

export default popup;