import Swiper from 'swiper';
import 'swiper/css';

// 年スライダー
const yearsSlider = () => {
    const el = document.querySelector('.js_yearsSlider');
    if (!el) return;

    let swiperInstance; //swiperインスタンス
    let currentPage; //現在のページ
    let currentSlide; //現在のスライド
    let slidesLength; //全体のスライド数
    const displayNum = 5; //1ページに表示するスライド数
    const prev = el.querySelector('.js_arrow_prev'); //前へ
    const next = el.querySelector('.js_arrow_next'); //次へ

    // swiperパラメータ
    const params = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        speed: 800
    }

    // swiper初期化
    const swiperInit = () => {
        const target = el.querySelector('.swiper'); //スライダー要素を取得
        swiperInstance = new Swiper(target, params); //初期化
        slidesLength = swiperInstance.slides.length; //全体のスライド数を取得

        // 初期化時点でのアクティブスライドの番号を取得
        for (let i = 0; i < slidesLength; i++) {
            const slide = swiperInstance.slides[i];
            if (slide.classList.contains('is_active')) {
                swiperInstance.activeIndex = i;
                break;
            }
        }

        getCurrentPage(); //現在のスライド番号＆ページ数を取得
        initNavigation(); //ナビゲーションを初期化
        displayNavigation(); //ナビゲーションの表示制御
    }

    // 現在のスライド番号＆ページ数を取得
    const getCurrentPage = () => {
        // 現在のスライド番号を取得
        currentSlide = swiperInstance.activeIndex;
        // スライド番号+1を表示数で割って切り上げ
        currentPage = Math.ceil((currentSlide + 1) / displayNum);
    }

    // ナビゲーションにクリックイベントを設定
    const initNavigation = () => {
        prev.addEventListener('click', () => slideTo('prev'));
        next.addEventListener('click', () => slideTo('next'));
    }

    // ナビゲーションの表示制御
    const displayNavigation = () => {
        // 全体のページ数が1ページの場合全て非表示
        if (slidesLength <= displayNum) {
            prev.classList.remove('is_show');
            next.classList.remove('is_show');
        
        // 最初のスライドの場合「前へ」を非表示
        } else if (currentSlide <= 0) {
            prev.classList.remove('is_show');
            next.classList.add('is_show');

        // 最後のスライドの場合「次へ」を非表示
        } else if (currentSlide >= slidesLength - displayNum) {
            prev.classList.add('is_show');
            next.classList.remove('is_show');
    
        // その他の場合全て表示
        } else {
            prev.classList.add('is_show');
            next.classList.add('is_show');
        }
    }

    /**
     * ナビゲーション
     * 
     * @param {string} direction - 方向 ('prev' | 'next')
     */
    const slideTo = (direction) => {
        // 前へ
        if (direction === 'prev') {
            swiperInstance.slideTo(currentSlide - displayNum);
        // 次へ
        } else if (direction === 'next') {
            swiperInstance.slideTo(currentSlide + displayNum);
        }
        getCurrentPage(); //現在のページを取得
        displayNavigation(); //ナビゲーションの表示制御
    }

    swiperInit();
}

export default yearsSlider
