import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/navigation';

// トップページ　ピックアップ　スライダー
const businessPickupSlider = () => {
    const el = document.querySelector('.js_businessPickupSlider');
    if (!el) return;

    let swiperInstance; //swiperインスタンス

    // swiper初期化
    const swiperInit = () => {
        const params = {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 25,
            navigation: {
                nextEl: '.js_next',
                prevEl: '.js_prev',
            },
            breakpoints: {
                768: {
                    spaceBetween: 53,
                }
            }
        }
        swiperInstance = new Swiper(el, params);
    }

    swiperInit();
}

export default businessPickupSlider
