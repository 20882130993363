import getDevice from '../module/getDevice';

const fixCategories = () => {
    if (getDevice() !== 'pc') return; //スマホの場合は処理しない

    const header = document.querySelector('.js_header');
    const target = document.querySelector('.js_categories');
    const targetWrap = document.querySelector('.js_categoriesWrap');
    if (!target || !targetWrap) return; //カテゴリがない場合は処理しない

    let rect;
    let targetPos;
    const headerH = header.getBoundingClientRect().height;

    // ページがロードし終わったら発火
    const doWhenLoaded = () => {
        rect = target.getBoundingClientRect(); //発火ポイントを取得
        targetPos = rect.top - rect.height + window.scrollY - headerH; //要素のトップからの距離
        doWhenScroll(); //初期実行
    }

    // スクロールしたら発火
    const doWhenScroll = () => {
        if (!targetPos) return;
        
        const scroll = window.scrollY; //スクロール量

        // スクロールが要素の位置を超えたらfixedクラスを付与
        if (scroll > targetPos) {
            target.classList.add('is_fixed');
            targetWrap.style.height = rect.height + 'px'; //親要素にheightを設定（追従時のレイアウトシフト防止）
        } else {
            target.classList.remove('is_fixed');
            targetWrap.style.height = 'auto'; //デバイスによって余白ができる場合があるので戻す
        }
    }
    
    window.addEventListener('load', doWhenLoaded);
    window.addEventListener('scroll', doWhenScroll);
}

export default fixCategories;