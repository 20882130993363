import scrollTo from './scrollTo';
import wowInit from './wowInit';

// タブ切り替え
const switchTab = () => {
    const targetList = document.querySelectorAll('.js_tab');
    if (!targetList.length) return;

    targetList.forEach(el => {
        const triggerList = el.querySelectorAll('.js_tabTrigger');
        const bodyList = el.querySelectorAll('.js_tabBody');
        const bodyWrap = el.querySelector('.js_tabBodyWrap');

        /**
         * スクロール
         */
        const scroll = () => {
            const headerisFixed= true; //ヘッダーが追従するかどうか

            // ヘッダーが追従する場合、ヘッダーの高さを取得
            let headerH = 0;
            if (headerisFixed) {
                headerH = document.querySelector('.js_header').clientHeight;
            }
            
            // タブ中身の位置を取得
            const rect = bodyWrap.getBoundingClientRect().top; // ブラウザからの高さ
            const offset = window.scrollY; // 現在のスクロール量
            const bodyPos = rect + offset - headerH; //最終的な位置を割り出す
            
            // スクロール
            scrollTo(bodyPos);
        }

        /**
         * トリガー要素がクリックされた時に呼ばれる関数
         * 
         * @var {number} nth クリックされた要素の番号
        */
        const changeActive = (nth) => {
            // 一旦全てのアクティブクラスを削除
            triggerList.forEach(trigger => {
                trigger.classList.remove('is_active');
            });
            bodyList.forEach(body => {
                body.classList.remove('is_active');
            });

            // 該当する番号の要素にアクティブクラス付与
            triggerList[nth].classList.add('is_active');
            bodyList[nth].classList.add('is_active');
        }

        // それぞれのトリガー要素にクリックイベントを設定
        for (let i = 0, len = triggerList.length; i < len; ++i) {
            triggerList[i].addEventListener('click', () => {
                changeActive(i); //タブ切り替え
                scroll(); //スクロール
                wowInit(); //wow.js初期化 (スクロール量をリセット)
            });
        }
    });
}

export default switchTab;