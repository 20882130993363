import getMq from './getMq';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// トップページ　サービス　スライダー
const topServiceSlider = () => {
    const el = document.querySelector('.js_topServiceSlider');
    if (!el) return;

    // swiper初期化
    const swiperInit = () => {
        const params = {
            modules: [Navigation, Pagination],
            speed: 500,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 120,
            loop: true,
            pagination: {
                el: '.js_serviceSlider_pagination',
                clickable: true,
            },
            breakpoints: {
                768: {
                    navigation: {
                        nextEl: '.js_serviceSlider_next',
                        prevEl: '.js_serviceSlider_prev',
                    },
                }
            }
        }
        const swiper = new Swiper(el, params);
    }

    // ナビゲーションの座標を設定 (画像を起点とした位置)
    const setArrowPos = () => {
        const image = el.querySelector('.js_topServiceSlider_img'); //画像（最初の1つのみ）
        const prev = el.querySelector('.js_serviceSlider_prev'); //前へ
        const next = el.querySelector('.js_serviceSlider_next'); //次へ
        
        // 座標設定関数
        const doWhenResize = () => {
            const mq = getMq(); //メディアクエリ
            const winW = window.innerWidth; //ウィンドウ幅
            const top = image.clientHeight / 2; //top(画像の高さの半分)
            let x; //左右

            // メディアクエリlg以下
            if (mq !== 'lg' && mq !== 'xl') {
                x = 20;
            //メディアクエリlg以上
            } else {
                x = ((winW - image.clientWidth) / 2) - 10;
            }

            prev.style.top = `${top}px`;
            prev.style.left = `${x}px`;
            next.style.top = `${top}px`;
            next.style.right = `${x}px`;
        }

        doWhenResize(); //初期実行
        window.addEventListener('resize', doWhenResize); //リサイズした時に実行
    }

    swiperInit();
    setArrowPos();
}

export default topServiceSlider