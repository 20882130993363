import getMq from './getMq';
import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// トップページ　サービス　スライダー
const topMvSlider = () => {
    const el = document.querySelector('.js_topMvSlider');
    if (!el) return;

    // swiper初期化
    const swiperInit = () => {
        const params = {
            modules: [Autoplay, EffectFade],
            speed: 2000,
            // centeredSlides: true,
            slidesPerView: 1,
            loop: true,
            effect: 'fade',
            autoplay: {
                delay: 4500,  // 2500ミリ秒ごとにスライド
                disableOnInteraction: false  // ユーザーの操作後も自動再生を続ける
            }
        }
        const swiper = new Swiper(el, params);
    }

    swiperInit();
}

export default topMvSlider