import getDevice from "./getDevice";

// introduction アニメーション
const topIntroductionAnimation = () => {
    const target = document.querySelector('.js_topIntroAnimation'); //対象要素
    if (!target) return;
    
    /**
     * タイピングアニメーション（スクロールで発火）
     * @param {HTMLElement} target 対象要素
     * @param {number} dulation 間隔（ミリ秒）
     * @param {number} delay 開始までの時間（ミリ秒）
     */
    const textTyping = (target, dulation = 100, delay = 0) => {
        if (!target) return;

        // 初期設定
        const init = () => {
            //テキストを1文字ずつspanで囲む
            let  text = '';
            for (let char of target.textContent) {
                text += `<span class="js_fadeIn">${char}</span>`;
            }
            target.innerHTML = text;
        }

        // 文字出現関数
        const show = () => {
            let i = 0;
            const char = target.getElementsByTagName('span');

            setTimeout(() => { //delayの秒数遅延
                const interval = setInterval(() => { //dulationの間隔で繰り返す
                    if (i < char.length) {
                        char[i].classList.add('is_show'); //カウントが文字数以内であればクラスを付与
                        i++;
                    } else {
                        clearInterval(interval); //文字数を超えたら終了
                    }
                }, dulation);
            }, delay);
        }

        init();
        show();
    }

    /**
     * フェードイン
     * @param {HTMLElement} target 
     */
    const fadeIn = (target) => {
        if (!target) return;
        target.classList.add('is_show');
    }

    /**
     * アニメーションを開始させる
     * 「Warm Heart」タイピングアニメーション　→　テキストエリアと画像をフェードイン
     * 処理するたびに遅延秒数を加算していくことで非同期処理のような動きをします。
     */
    const startAnimation = () => {
        let delay = 0; //遅延秒数
        
        // タイピングアニメーションを開始
        const startTyPing = () => {
            const elList = target.querySelectorAll('.js_textTyping'); //対象要素
            const dulation = 30; //間隔
            let letterLength = 0; //これまでに表示した文字数

            for (let i = 0; i < elList.length; i++) {
                const el = elList[i];
                letterLength += el.textContent.length; //文字数を取得

                el.classList.remove('hp_hidden'); //初期描画用のクラスを削除
                textTyping(el, dulation, delay);

                // 最後の要素でなければ遅延秒数を加算
                if (elList.length !== i + 1) {
                    delay += dulation * letterLength; //1文字にかかる時間×文字数（全て表示し終わった後に次へ）
                }
            }
        }

        // フェードインアニメーションを開始
        const startFadeIn = () => {
            const text = document.querySelector('.js_textFadeIn'); //テキスト要素
            const imgList = target.querySelectorAll('.js_imgFadeIn'); //画像要素
            delay += 400; //タイピングアニメーション後の間隔
            const interval = 300; //テキストフェードイン後の間隔
            
            // テキストフェードイン
            setTimeout(() => fadeIn(text), delay);
            
            delay += interval; //遅延を加算

            // 画像フェードイン
            setTimeout(() => {
                for (let i = 0; i < imgList.length; i++) {
                    const el = imgList[i];
                    fadeIn(el);
                }
            }, delay);
        }

        startTyPing();
        startFadeIn();
    }

    /**
     * 一定のスクロールに達したら発火
    */
    const scrollTrigger = () => {
        let visible = false; //状態管理
        const offset = getDevice() === 'sp' ? 100 : 300; //発火する位置

        // スクロールするたびに呼ばれる関数
        const doWhenScroll = () => {
            if (visible) return; //既に表示されていたら処理しない
    
            const scroll = window.scrollY; //スクロール量
            const targetPos = target.getBoundingClientRect().top + scroll; //対象要素の位置
            visible = scroll + window.innerHeight - offset > targetPos; //発火する箇所まできたらvisibleをtrueにする
    
            if (visible) startAnimation(); //アニメーション開始
        }

        if (window.scrollY === 0) doWhenScroll(); //初期描画
        window.addEventListener("scroll", doWhenScroll); // スクロールするたびに実行
    }

    scrollTrigger();
}

export default topIntroductionAnimation