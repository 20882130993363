import getDevice from './getDevice';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

// トップページ　ピックアップ　スライダー
const topPartnerSlider = () => {
    const el = document.querySelector('.js_topPartnerSlider');
    if (!el) return;

    let swiperInstance; //swiperインスタンス

    // swiper初期化
    const swiperInit = () => {
        const params = {
            modules: [Autoplay],
            speed: 3000,
            slidesPerView: 'auto',
            loop: true,
            autoplay: {
                delay: 0, // 途切れなくループ
            },
            breakpoints: {
                768: {
                    spaceBetween: 30,
                }
            }
        }
        swiperInstance = new Swiper(el, params);
    }

    // 画面幅によって有効/無効を切り替え
    const switchSwiper = () => {
        if (!swiperInstance) return;
        
        const device = getDevice(); //sp or pc
        
        // PCの場合
        if (device === 'pc') {
            swiperInstance.enable();
            
        // SPの場合
        } else {
            swiperInstance.disable();
        }
    }

    swiperInit();
    switchSwiper(); //初期実行
    window.addEventListener('resize', switchSwiper); //リサイズした時に実行
}

export default topPartnerSlider
