// 背景色が伸びて出現するアニメーション（wow.jsフェードインと連動）
const bgFlowOnScroll = () => {
    // 対象とするノードを取得
    const targetList = document.querySelectorAll('.js_bgFlowOnScroll');
    if (!targetList.length) return;

    for (let i = 0; i < targetList.length; i++) {
        const target = targetList[i];
        let done = false;
        target.classList.remove('is_animated'); //一旦実行済みクラスを削除（リセット用）
        
        // オブザーバインスタンスを作成
        const observer = new MutationObserver(function() {
            if (target.style.visibility === 'hidden') return; //要素がまだ表示されていなければ実行しない
            if (done) return; //過去に一度でも実行されていれば実行しない
            target.classList.add('is_animated'); //アニメーションを実行
            done = true;
        });
    
        // オブザーバの設定
        const config = {
            attributes: true //属性を監視
        };
    
        // 対象ノードとオブザーバの設定を渡す
        observer.observe(target, config);
    }
}

export default bgFlowOnScroll;