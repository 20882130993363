import getDevice from '../module/getDevice';
import scrollTo from './scrollTo';

/**
 * 支店案内　絞り込み 
*/
const branchFilter = () => {
    const triggerList = document.querySelectorAll('.js_filter_trigger'); //ボタンを取得
    if (!triggerList.length) return; //ボタンが存在しなければ処理しない
    
    const url = new URL(window.location.href); //URL
    const currentPage = url.pathname.split('/').pop().replace(/\.[^/.]+$/, ''); //ファイル名からページIDを抽出
    let currentId = 'all'; //現在の表示項目
    const title = document.querySelector('.js_filter_title'); //タイトル要素を取得
    const targetList = document.querySelectorAll('.js_filter_target'); //コンテンツ要素を取得


    /**
     * URLパラメータを置き換え
     */
    const setParames = () => {
        // カテゴリが「全て」の場合、既存のパラメータを削除
        if (currentId === 'all') {
            url.searchParams.delete('area');
            window.history.pushState({ path: url.href }, '', url.href);
        // カテゴリが「全て」以外の場合、パラメータを追加
        } else {
            url.searchParams.set('area', currentId);
            window.history.pushState({ path: url.href }, '', url.href);
        }
    }


    /**
     * タイトルの変更
     */
    const switchTitle = () => {
        let textContent = 'すべての拠点・支店';

        const titleMap = {
            domestic: {
                '01': '北海道',
                '02': '東北',
                '03': '北関東',
                '04': '首都圏',
                '05': '甲信越',
                '06': '東海',
                '07': '北陸',
                '08': '近畿',
                '09': '山陰/山陽',
                '10': '四国',
                '11': '九州',
                '12': '沖縄',
            },
            overseas: {
                '01': 'アメリカ',
                '02': 'イギリス',
                '03': '中国',
                '04': 'シンガポール',
                '05': 'タイ',
                '06': 'オーストラリア',
            }
        }

        if (currentId !== 'all' && titleMap[currentPage][currentId]) {
            textContent = `${titleMap[currentPage][currentId]}`;
        }

        title.textContent = textContent;
    }


    /**
     * DOMを切り替える
     */
    const switchDOM = () => {
        // ボタンに対する処理
        triggerList.forEach(el => {
            if (el.dataset.filter === currentId) {
                el.classList.add('is_active'); //選択されているカテゴリのボタンにアクティブクラスを付与
            } else {
                el.classList.remove('is_active'); //それ以外全てのアクティブクラスを削除
            }
        });
        
        //コンテンツに対する処理
        targetList.forEach(el => {
            // currentIdがallの場合
            if (currentId === 'all') {
                el.classList.add('is_show'); //全て表示
            // currentIdがall以外の場合
            } else {
                if (el.dataset.filter === currentId) {
                    el.classList.add('is_show'); //currentIdに該当する項目を表示
                } else {
                    el.classList.remove('is_show'); //それ以外は非表示
                }
            }
        });
    }


    /**
     * コンテンツ領域にスクロール
     */
    const anchorLink = () => {
        const target = document.getElementById('contents');
        const header = document.querySelector('.js_header');
        const categories = document.querySelector('.js_categories');
        if (!target || !categories) return;

        const headerH = header.clientHeight; //ヘッダーの高さ
        const categoriesH = getDevice() !== 'pc' ? 0 : categories.clientHeight; //カテゴリ一覧の高さ(SPの場合は0)
        const rect = target.getBoundingClientRect().top; //遷移先のブラウザからの高さ
        const offset = window.scrollY; // 現在のスクロール量
        const targetPos = rect + offset - headerH - categoriesH; //最終的な位置を割り出す  
        scrollTo(targetPos);
    }


    /**
     * ページがロードされた時に呼ばれる関数
     */
    const doWhenLoaded = () => {
        const param = url.searchParams.get('area'); //URLパラメータからカテゴリを取得
        if (!param) return; //パラメータが存在しなければ処理しない

        // パラメータのfilter IDが存在するかを確認
        let idExists = false;
        for (let i = 0; i < triggerList.length; i++) {
            if (triggerList[i].dataset.filter !== param) continue;
            idExists = true;
        }
        if (!idExists) return; //IDが存在しなければ処理しない

        currentId = param; //現在の表示項目を更新
        switchTitle(); //タイトルの変更
        switchDOM(); //DOMを切り替える
        anchorLink(); //コンテンツ領域にスクロール
    }

    window.addEventListener('load', doWhenLoaded);


    /**
     * クリックされた時に呼ばれる関数
     * @var {HTMLElement} trigger クリックされたボタン要素
     * */
    const doWhenClicked = (trigger) => {
        currentId = trigger.dataset.filter; //現在の表示項目を更新
        setParames(); //パラメータを追加
        switchTitle(); //タイトルの変更
        switchDOM(); //DOMを切り替える
        anchorLink(); //コンテンツ領域にスクロール
    }

    // ボタンにクリックイベントを設定
    triggerList.forEach(el => {
        el.addEventListener('click', (e) => {
            doWhenClicked(e.target);
        });
    });
}

export default branchFilter;