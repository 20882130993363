const headerFixed = () => {
    const header = document.querySelector('.js_header');
    if (!header) return;

    // ページ読み込みの時点で背景が白であれば処理の必要がないので終了
    if (header.classList.contains('is_white')) return;

    // スクロールした時に呼ばれる関数
    const doWhenScroll = () => {
        const targetPos = 300;
        const scroll = window.scrollY;

        // 背景を白にする
        if (scroll > targetPos) {
            header.classList.add('is_white');
        } else {
            header.classList.remove('is_white');
        }
    }
    
    window.addEventListener('scroll', doWhenScroll);
}

export default headerFixed;